<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных сотрудника
      </h4>
      <div class="alert-body">
        Не найден сотрудник с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'employees'}"
        >
          Список сотрудников
        </b-link>
        и выбрать другого.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <div>
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Сотрудник"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="`${get(item, 'user.surname')} ${get(item, 'user.name')} ${get(item, 'user.patronymic')}`"
                plaintext
                style="font-weight: bold !important;"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Роль *"
              label-for="role_id"
            >
              <v-select
                id="role_id"
                v-model.trim="item.role_id"
                placeholder="Выберите роль"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roles"
                :reduce="val => val.id"
                :clearable="false"
                input-id="user-role"
                label="name"
                :class="{
                  'is-invalid': !!get(validation, 'role_id[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'role_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'role_id[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">Разрешения</span>
            </b-card-title>
          </b-card-header>
          <b-table-simple
            :striped="false"
            :bordered="false"
            :borderless="false"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="m-0"
          >
            <b-thead>
              <b-th>
                Персональное
              </b-th>
              <b-th>
                От роли
              </b-th>
              <b-th>
                Описание
              </b-th>
            </b-thead>
            <b-tbody
              v-for="permissionCategory in groupedPermissions"
              :key="'c' + permissionCategory.id"
            >
              <b-tr>
                <b-th
                  colspan="3"
                  class="bg-light"
                >
                  {{ permissionCategory.name }}
                </b-th>
              </b-tr>
              <b-tr
                v-for="permission in permissionCategory.permissions"
                :key="'p' + permission.id"
              >
                <b-td>
                  <v-select
                    :id="'p' + permission.code"
                    v-model.trim="item.permissions[permission.code]"
                    placeholder="Разрешение"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="[
                      { name: 'Да', value: 1 },
                      { name: 'Нет', value: 0 },
                      { name: 'От роли', value: 2 },
                    ]"
                    :reduce="val => val.value"
                    :clearable="false"
                    label="name"
                    style="min-width: 123px;"
                  />
                </b-td>
                <b-td>
                  <feather-icon
                    :icon="permission.active_role_value ? 'CheckIcon': 'MinusIcon'"
                    size="20"
                    :class="{
                      'text-success': permission.active_role_value,
                      'text-secondary': !permission.active_role_value,
                    }"
                  />
                </b-td>
                <b-td>
                  <div class="">
                    {{ permission.name }}
                  </div>
                  <div class="small text-secondary">
                    {{ permission.desc }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card no-body>
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="BoxIcon"
            size="18"
          />
          <span class="align-middle ml-50">Доступ к проектам</span>
        </b-card-title>
      </b-card-header>

      <b-table-simple
        :striped="true"
        :bordered="false"
        :borderless="true"
        :outlined="false"
        :small="false"
        :hover="false"
        :dark="false"
        :fixed="false"
        :responsive="true"
        class="m-0"
      >
        <b-tbody>
          <b-tr
            v-for="itemProject in item.projects"
            :key="'p' + itemProject.project_id"
          >
            <b-td
              class="w-25"
            >
              <b-form-checkbox
                :id="'ip' + itemProject.project_id"
                v-model="itemProject.value"
                switch
                :value="1"
                :unchecked-value="0"
                class="custom-control-inline"
                @change="updateEmployeeProject(itemProject)"
              >
                <template v-if="itemProject.value">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
            </b-td>
            <b-td>
              {{ itemProject.name !== 'Default' ? itemProject.name : $store.getters['workingMode/selected_company_name'] }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BTab, BTabs,
  BFormTextarea, BInputGroup, BCardHeader,
  BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BThead, BTr, BTd, BTh,
} from 'bootstrap-vue'

import get from 'lodash/get'

import {
  getCurrentInstance, ref, onMounted, computed,
} from 'vue'

import vSelect from 'vue-select'
import useCrudEdit from '@/composables/useCrudEdit'
import { getPermissions } from '@/services/main-api/business/permissions/permissions'
import { getPermissionCategories as getPermissionCategoriesApi } from '@/services/main-api/business/permissions/categories'
import { getRoles as getRolesApi } from '@/services/main-api/business/roles'
import {
  updateEmployee, getEmployee, updateEmployeePermissions, updateEmployeeProject as updateEmployeeProjectApi,
} from '@/services/main-api/business/employees'
import { getProjects as getProjectsApi } from '@/services/main-api/business/projects'

export default {
  name: 'EditEmployeeView',
  components: {
    BAlert,
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    BOverlay,
    BRow,
    BTab,
    BTabs,
    BFormTextarea,
    BInputGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTd,
    BTh,
    vSelect,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    // BEGIN permissions
    const permissions = ref([])
    const permissionCategories = ref([])

    const getAllPermissions = async () => {
      try {
        const res = await getPermissions({
          perPage: 1000,
          page: 1,
        })
        permissions.value = res.data.data
      } catch (e) {
        permissions.value = []
      }
    }

    const getPermissionCategories = async () => {
      try {
        const res = await getPermissionCategoriesApi({
          perPage: 1000,
          page: 1,
        })
        permissionCategories.value = res.data.data
      } catch (e) {
        permissionCategories.value = []
      }
    }

    const editPermissions = async () => {
      const tempPermissions = Object.entries(instance.item.permissions)
        .map(i => ({
          code: i[0],
          value: i[1],
        }))

      if (tempPermissions.length) {
        try {
          await updateEmployeePermissions({
            id: instance.item.id,
            permissions: tempPermissions,
          })
        } catch (e) {
          // ...
        }
      }
    }
    // END permissions

    // BEGIN Roles
    const roles = ref([])

    const getRoles = async () => {
      try {
        const res = await getRolesApi({
          company: instance.$store.getters['workingMode/company_id'],
          perPage: 1000,
          page: 1,
        })
        roles.value = res.data.data.filter(i => i.code !== 'super_admin')
      } catch (e) {
        roles.value = []
      }
    }
    // END Roles

    // BEGIN Projects
    const projects = ref([])

    const getProjects = async () => {
      try {
        const res = await getProjectsApi({
          perPage: 1000,
          page: 1,
          search: `company_id:${instance.$store.getters['workingMode/company_id']}`,
        })

        projects.value = res.data.data
      } catch (e) {
        projects.value = []
      }
    }

    const updateEmployeeProject = async itemProject => {
      try {
        await updateEmployeeProjectApi({
          id: instance.item.id,
          project_id: itemProject.project_id,
          value: itemProject.value,
        })
      } catch (e) {
        // ...
      }
    }
    // END Projects

    const getItem = async () => {
      try {
        const res = await getEmployee(props.id)
        const tempItem = res.data.data

        await getProjects()

        tempItem.projects = tempItem.projects.map(ip => ({
          ...ip,
          name: get(projects.value.find(p => p.id === ip.project_id), 'name'),
        }))

        instance.item = tempItem
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const updateEntity = async () => {
      await Promise.all([
        updateEmployee({
          id: instance.item.id,
          role_id: instance.item.role_id,
        }),
        editPermissions(),
      ])
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        user_id: '',
        user: {},
        role_id: '',
        projects: [],
        permissions: {},
      },
      validation: {
        rules: {
          // name: 'required',
        },
        customErrorMessages: {
          // 'required.name': 'Укажите название',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Сотрудник был изменен.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
      getMoreData: async () => {
        await Promise.all([
          getRoles(),
          getAllPermissions(),
          getPermissionCategories(),
        ])
      },
    })

    const activeRolePermissions = computed(() => get(roles.value.find(r => r.id === item.value.role_id), 'permissions', {}))

    const groupedPermissions = computed(() => permissionCategories.value.map(c => ({
      ...c,
      permissions: permissions.value.filter(p => p.permission_category_id === c.id).map(gp =>
        // ...
        ({
          ...gp,
          active_role_value: activeRolePermissions.value[gp.code],
        })),
    })))

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        this.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,

      roles,

      permissionCategories,
      groupedPermissions,

      projects,
      updateEmployeeProject,

      get,
      getItemError,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
