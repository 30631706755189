var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getItemError)?_c('div',[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Ошибка при получении данных сотрудника ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" Не найден сотрудник с этим идентификатором. Можете перейти в "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'employees'}}},[_vm._v(" Список сотрудников ")]),_vm._v(" и выбрать другого. ")],1)])],1):_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary"}},[_c('b-card',[_c('div',[(_vm.error_message)?_c('b-row',[_c('b-col',[_c('small',{staticClass:"text-danger d-block mb-1"},[_vm._v(" "+_vm._s(_vm.error_message)+" ")])])],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Сотрудник","label-for":"name"}},[_c('b-form-input',{staticStyle:{"font-weight":"bold !important"},attrs:{"id":"name","value":`${_vm.get(_vm.item, 'user.surname')} ${_vm.get(_vm.item, 'user.name')} ${_vm.get(_vm.item, 'user.patronymic')}`,"plaintext":""}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Роль *","label-for":"role_id"}},[_c('v-select',{class:{
                'is-invalid': !!_vm.get(_vm.validation, 'role_id[0]')
              },attrs:{"id":"role_id","placeholder":"Выберите роль","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roles,"reduce":val => val.id,"clearable":false,"input-id":"user-role","label":"name"},model:{value:(_vm.item.role_id),callback:function ($$v) {_vm.$set(_vm.item, "role_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.role_id"}}),(!!_vm.get(_vm.validation, 'role_id[0]'))?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.get(_vm.validation, 'role_id[0]'))+" ")]):_vm._e()],1)],1)],1),_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Разрешения")])],1)],1),_c('b-table-simple',{staticClass:"m-0",attrs:{"striped":false,"bordered":false,"borderless":false,"outlined":false,"small":false,"hover":false,"dark":false,"fixed":false,"responsive":true}},[_c('b-thead',[_c('b-th',[_vm._v(" Персональное ")]),_c('b-th',[_vm._v(" От роли ")]),_c('b-th',[_vm._v(" Описание ")])],1),_vm._l((_vm.groupedPermissions),function(permissionCategory){return _c('b-tbody',{key:'c' + permissionCategory.id},[_c('b-tr',[_c('b-th',{staticClass:"bg-light",attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(permissionCategory.name)+" ")])],1),_vm._l((permissionCategory.permissions),function(permission){return _c('b-tr',{key:'p' + permission.id},[_c('b-td',[_c('v-select',{staticStyle:{"min-width":"123px"},attrs:{"id":'p' + permission.code,"placeholder":"Разрешение","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":[
                    { name: 'Да', value: 1 },
                    { name: 'Нет', value: 0 },
                    { name: 'От роли', value: 2 },
                  ],"reduce":val => val.value,"clearable":false,"label":"name"},model:{value:(_vm.item.permissions[permission.code]),callback:function ($$v) {_vm.$set(_vm.item.permissions, permission.code, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.permissions[permission.code]"}})],1),_c('b-td',[_c('feather-icon',{class:{
                    'text-success': permission.active_role_value,
                    'text-secondary': !permission.active_role_value,
                  },attrs:{"icon":permission.active_role_value ? 'CheckIcon': 'MinusIcon',"size":"20"}})],1),_c('b-td',[_c('div',{},[_vm._v(" "+_vm._s(permission.name)+" ")]),_c('div',{staticClass:"small text-secondary"},[_vm._v(" "+_vm._s(permission.desc)+" ")])])],1)})],2)})],2)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('b-button',{attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.updateItem}},[_vm._v(" Сохранить ")])],1)],1)],1)]),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"BoxIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Доступ к проектам")])],1)],1),_c('b-table-simple',{staticClass:"m-0",attrs:{"striped":true,"bordered":false,"borderless":true,"outlined":false,"small":false,"hover":false,"dark":false,"fixed":false,"responsive":true}},[_c('b-tbody',_vm._l((_vm.item.projects),function(itemProject){return _c('b-tr',{key:'p' + itemProject.project_id},[_c('b-td',{staticClass:"w-25"},[_c('b-form-checkbox',{staticClass:"custom-control-inline",attrs:{"id":'ip' + itemProject.project_id,"switch":"","value":1,"unchecked-value":0},on:{"change":function($event){return _vm.updateEmployeeProject(itemProject)}},model:{value:(itemProject.value),callback:function ($$v) {_vm.$set(itemProject, "value", $$v)},expression:"itemProject.value"}},[(itemProject.value)?[_vm._v(" Вкл ")]:[_vm._v(" Выкл ")]],2)],1),_c('b-td',[_vm._v(" "+_vm._s(itemProject.name !== 'Default' ? itemProject.name : _vm.$store.getters['workingMode/selected_company_name'])+" ")])],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }