import axiosIns from '@/libs/axios'

export function getEmployee(id) {
  return axiosIns.get(`/business/employees/${id}`)
}

export function getMeByCompany(companyId) {
  return axiosIns.get(`/business/employees/me/${companyId}`)
}

export function getEmployees({
  company, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/business/employees', {
    params: {
      company,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function updateEmployee({ id, role_id }) {
  return axiosIns.patch(`/business/employees/${id}`, {
    role_id,
  })
}

export function deleteEmployee(id) {
  return axiosIns.delete(`/business/employees/${id}`)
}

/**
 * Изменение значения права сотрудника
 *
 * @param id Идентификатор сотрудника, указывается в адресе запроса
 * @param permissions Массив с разрешениями и новыми значениями. Рекомендуется передавать только те разрешения, которые должны быть изменены. Передавать весь список, включая те, которые не подвергаются изменениям не имеет смысла.
 * @param permissions[].code Код разрешения
 * @param permissions[].value Допустимые значения: 0 (запрещено), 1 (разрешено), 2 (наследовать от роли)
 * @returns {*}
 */
export function updateEmployeePermissions({ id, permissions }) {
  return axiosIns.patch(`/business/employees/${id}/permissions`, {
    permissions,
  })
}

/**
 * Изменение права доступа сотрудника к проекту
 *
 * @param id Идентификатор сотрудника, указывается в адресе запроса
 * @param project_id Идентификатор проекта компании
 * @param value Допустимые значения: 0 (запрещено), 1 (разрешено)
 * @returns {*}
 */
export function updateEmployeeProject({ id, project_id, value }) {
  return axiosIns.patch(`/business/employees/${id}/project`, {
    project_id,
    value,
  })
}

export default {
  getEmployee,
  getMeByCompany,
  getEmployees,
  updateEmployee,
  deleteEmployee,
  updateEmployeePermissions,
  updateEmployeeProject,
}
